/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
// import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Section from './Section';
import ContentBlock from './ContentBlock';
import FormBlock from './FormBlock';
import ContentHeader from './ContentHeader';
import InputBlock from './InputBlock';
import Button from './Button';
import TextAreaBlock from './TextAreaBlock';
// SERVICES AND HELPERS
import * as helper from '../helpers/helper';

const ContactForm = (props) => {
  const {
    formConfig,
    title,
    onSubmit,
    isSubmitting,
    submitButtonLabel,
    submitButtonClasses
  } = props;
  const captchaRef = useRef();
  const [captchaToken, setCaptchaToken] = useState(null);
  const formik = useFormik({
    initialValues: formConfig.reduce((prev, curr) => {
      prev[curr.name] = '';
      return prev;
    }, {}),
    onSubmit: (values, { setSubmitting, resetForm }) => {
      if (!captchaToken) {
        setSubmitting(false);
        return;
      }

      const submissionValues = {
        ...values,
        reCAPTCHAToken: captchaToken,
      };

      onSubmit(submissionValues, { setSubmitting, resetForm });

      captchaRef.current.reset();
      setCaptchaToken(null);
    },
  });

  return (
    <FormBlock className="extended">
      <ContentHeader title={title} headerSize="xl" />
      <Section hasNoContainer>
        {formConfig.map((field) => (
          <ContentBlock
            className="static-label dark--clr"
            cols={field.cols || 6} key={field.name}
          >
            {field.textarea ? (
              <TextAreaBlock
                isRequired={field.required}
                label={field.label}
                placeholder={field.placeholder}
                errorMessage={formik.errors[field.name]}
                inputState={`${helper.getInputClasses(formik, field.name)}`}
                {...formik.getFieldProps(field.name)}
              />
            ) : (
              <InputBlock
                isRequired={field.required}
                label={field.label}
                placeholder={field.placeholder}
                errorMessage={formik.errors[field.name]}
                inputState={`${helper.getInputClasses(formik, field.name)}`}
                mask={field.name === 'phoneNumber' ? '(999) 999-9999' : undefined}
                {...formik.getFieldProps(field.name)}
              />
            )}
          </ContentBlock>
        ))}
      </Section>

      <CustomBlock className="content-container--actions mt-40">
        {captchaToken == null ? (
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={setCaptchaToken}
            // theme="dark"
            className="recaptcha"
          />
        ) : (
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            text={`${submitButtonLabel || 'Submit'}`}
            className={`${submitButtonClasses || 'secondary--sbg'}`}
            size="sm"
            onClick={formik.handleSubmit}
          />
        )}
      </CustomBlock>
    </FormBlock>
  );
};

ContactForm.propTypes = {
  showAlert: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  formConfig: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      max: PropTypes.number,
      email: PropTypes.bool,
      textarea: PropTypes.bool,
      cols: PropTypes.number,
    })
  ).isRequired,
};

ContactForm.defaultProps = {
  showAlert: () => { },
  title: 'Contact Form',
};

export default ContactForm;