/* eslint-disable react/self-closing-comp */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-body-style */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-one-expression-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// ICONS
import { FiFacebook, FiMenu } from 'react-icons/fi';
import { TiArrowSortedDown } from 'react-icons/ti';
import { RiInstagramLine, RiQuestionAnswerFill } from 'react-icons/ri';
import { MdMailOutline, MdSupervisedUserCircle } from 'react-icons/md';
import { TbMailFilled } from 'react-icons/tb';
import {
  FaPhoneAlt,
  FaFacebookF,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Button from './Button';
import Image from './Image';
import DrawerNavigation from './DrawerNavigation';
import SelectBlock from './SelectBlock';
import Chip from './Chip';
import Modal from './ModalBlock';
import Section from './Section';
// ASSETS
// SERVICES
import * as menuItemService from '../services/cms/menuItemService';

const HeaderNavigation = (props) => {
  const {
    auth,
    cmsInfo,
    // layout,
    phoneNumber,
    emailAddress,
    backgroundColor,
    menuItemsBlockBackgroundColor,
    menuItemColor,
    menuToggleIconColor,
    menuToggleIconSize,
    mobileMenuItemColor,
    submenuBlockBackgroundColor,
    submenuItemColor,
    fixedNavigationBarBackgroundColor,
    logo,
    logoAlt,
    icon,
    iconSize,
    iconAppearance,
    socialIconColor,
    socialIconBackgroundColor,
    primarySocialMediaLink,
    secondarySocialMediaLink,
    hasSearchBlock,
    menuItems,
    hasChipFilter,
    hasRoundCorners,
    history,
  } = props;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [expandedMenuId, setExpandedMenuId] = useState(null);
  const [scroll, setScroll] = useState(1);

  const SocialIconButton = (socialIconButtonProps) => {
    const {
      displayIf,
      buttonColor,
      buttonText,
      socialIcon,
      onClickAction,
      className,
      title
    } = socialIconButtonProps;
    return displayIf && (
      <button
        onClick={onClickAction}
        className={className}
        title={title}
        style={{ background: `${buttonColor}` }}
      >
        {socialIcon}
        {buttonText && <p className="white--clr fw-500">{buttonText}</p>}
      </button>
    );
  };

  const onScroll = () => {
    const scrollCheck = window.scrollY > 10;
    setScroll(scrollCheck);
  };

  useEffect(() => {
    onScroll();

    document.addEventListener('scroll', onScroll);
    return () => {
      document.removeEventListener('scroll', onScroll);
    };
  }, [scroll, setScroll]);

  const popularSelections = [
    { id: 1, name: 'Filter 1', url: '' },
    { id: 2, name: 'Filter 2', url: '' },
    { id: 3, name: 'Filter 3', url: '' },
    { id: 4, name: 'Filter 4', url: '' },
    { id: 5, name: 'Filter 5', url: '' },
  ];

  const iconAppearanceOptions = {
    square: 'square',
    round: 'round',
    circle: 'circle',
  };

  const openInNewTab = (link) => {
    window.open(link, '_blank');
  };

  const handleMenuItemClick = (menuItem) => {
    if (menuItem.pageUrl === '') {
      menuItemService.getMenuItemsById(menuItem.id).then((res) => {
        if (res.customLink && res.customLink.includes('https')) {
          openInNewTab(res.customLink);
        } else {
          history.push(res.customLink);
        }
      });
    }

    if (menuItem.pageUrl && menuItem.pageUrl !== '') {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.pageUrl);
      } else {
        history.push(menuItem.pageUrl);
      }
    } else if (menuItem.customLink) {
      if (menuItem.openInNewTab) {
        openInNewTab(menuItem.customLink);
      } else {
        history.push(menuItem.customLink);
      }
    }

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const navigateToPage = (page) => {
    history.push(page);

    setTimeout(() => {
      setIsDrawerOpen(false);
    }, 200);
  };

  const toggleMobileSubmenu = (menuId) => {
    if (expandedMenuId === menuId) {
      setExpandedMenuId(null);
    } else {
      setExpandedMenuId(menuId);
    }
  };

  const sendEmail = (email) => {
    window.open(`mailto:${email}`, '_self');
  };

  const callPhoneNumber = (tel) => {
    window.open(`tel:${tel}`, '_self');
  };

  const socialIconsRef = [
    {
      platform: 'youtube',
      displayIf: cmsInfo.youtubeLink !== null,
      title: `YouTube | ${cmsInfo.youtubeLink}`,
      buttonColor: '#F52424',
      socialIcon: <FaYoutube className={socialIconColor} size={iconSize} />,
      onClickAction: () => openInNewTab(cmsInfo.youtubeLink)
    },
    {
      platform: 'twitter',
      displayIf: cmsInfo.twitterLink !== null,
      title: `Twitter | ${cmsInfo.twitterLink}`,
      buttonColor: '#25A4EF',
      socialIcon: <FaTwitter className={socialIconColor} size={iconSize} />,
      onClickAction: () => openInNewTab(cmsInfo.twitterLink)
    },
    {
      platform: 'googleMap',
      displayIf: cmsInfo.googleMapLink !== null,
      title: `Google Maps | ${cmsInfo.googleMapLink}`,
      buttonColor: '#FAFAFA',
      onClickAction: () => openInNewTab(cmsInfo.googleMapLink),
      socialIcon: (
        <svg
          width="21"
          height="auto"
          viewBox="0 0 210 301"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M57.7653 222.501C66.4418 233.558 74.3385 245.205 81.3983 257.358C87.4261 268.787 89.9389 276.537 94.3441 290.309C97.0457 297.917 99.4874 300.188 104.737 300.188C110.457 300.188 113.053 296.325 115.058 290.35C119.222 277.345 122.489 267.42 127.644 258.041C137.759 239.935 150.328 223.845 162.678 208.382C166.021 204.005 187.638 178.508 197.369 158.39C197.369 158.39 209.33 136.291 209.33 105.427C209.33 76.5567 197.56 56.514 197.56 56.514L57.7653 222.501Z" fill="#34A853" />
          <path d="M10.3759 154.675C21.5822 180.272 43.192 202.772 57.8127 222.519L135.47 130.529C135.47 130.529 124.53 144.837 104.684 144.837C82.5779 144.837 64.7193 127.185 64.7193 104.926C64.7193 89.6619 73.9024 79.1758 73.9024 79.1758L10.3759 154.675Z" fill="#FBBC04" />
          <path d="M136.338 4.9981C162.129 13.3139 184.205 30.7719 197.558 56.5155L135.494 130.476C135.494 130.476 144.677 119.8 144.677 104.631C144.677 81.8537 125.497 64.8379 104.778 64.8379C85.1847 64.8379 73.9318 79.1226 73.9318 79.1226L136.338 4.9981Z" fill="#4285F4" />
          <path d="M24.6018 37.6777C40.0065 19.2469 67.1127 0.267578 104.483 0.267578C122.614 0.267578 136.273 5.02752 136.273 5.02752L73.873 79.1635L24.6018 37.6777Z" fill="#1A73E8" />
          <path d="M10.3759 154.675C10.3759 154.675 0.0664062 134.486 0.0664062 105.238C0.0664062 77.5948 10.8125 53.4313 24.6018 37.6777L73.9081 79.17L10.3759 154.675Z" fill="#EA4335" />
        </svg>
      ),
    },
    {
      platform: 'tikTok',
      displayIf: cmsInfo.tikTokLink !== null,
      title: `TikTok | ${cmsInfo.tikTokLink}`,
      buttonColor: '#080808',
      onClickAction: () => openInNewTab(cmsInfo.tikTokLink),
      socialIcon: (
        <svg
          width="23"
          height="auto"
          viewBox="0 0 130 147"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M95.7917 53.5281C105.199 60.2491 116.723 64.2036 129.169 64.2036V40.2651C126.814 40.2656 124.464 40.0202 122.16 39.5322V58.3751C109.714 58.3751 98.1917 54.4206 88.7822 47.7001V96.5517C88.7822 120.99 68.9612 140.799 44.5122 140.799C35.3897 140.799 26.9107 138.043 19.8672 133.315C27.9062 141.531 39.1172 146.627 51.5202 146.627C75.9707 146.627 95.7927 126.818 95.7927 102.379V53.5281H95.7917ZM104.439 29.3771C99.6312 24.1276 96.4747 17.3436 95.7917 9.84365V6.76465H89.1492C90.8212 16.2971 96.5242 24.4411 104.439 29.3771ZM35.3312 114.562C32.6452 111.042 31.1937 106.736 31.2002 102.309C31.2002 91.1316 40.2662 82.0692 51.4512 82.0692C53.5357 82.0687 55.6077 82.3876 57.5942 83.0176V58.5441C55.2727 58.2261 52.9297 58.0911 50.5877 58.1406V77.1897C48.5997 76.5597 46.5267 76.2396 44.4417 76.2416C43.0436 76.2416 41.6786 76.3832 40.3602 76.6528C39.4338 76.8423 38.5303 77.095 37.6547 77.4062C31.0581 79.7507 26.0372 85.4163 24.6051 92.3903C24.6042 92.3945 24.6034 92.3987 24.6026 92.4028C24.3328 93.7203 24.1912 95.0845 24.1912 96.4817C24.1912 99.4456 24.8284 102.26 25.9735 104.797C27.8819 109.024 31.2009 112.479 35.3312 114.562Z" fill="#FF004F" />
          <path d="M122.16 39.5322L122.159 34.437C115.895 34.4465 109.754 32.6931 104.439 29.3771C107.363 32.5773 110.864 35.1599 114.732 37.006C114.923 37.0973 115.115 37.1868 115.308 37.2745C117.492 38.2664 119.788 39.0257 122.16 39.5322ZM89.1492 6.76465C88.9897 5.85365 88.8667 4.937 88.7817 4.0165V0.9375H64.6907V96.5515C64.6522 107.696 55.6022 116.722 44.4402 116.722C41.1642 116.722 38.071 115.945 35.3315 114.564L35.3292 114.563C38.0105 118.08 41.8169 120.693 46.2121 121.866C46.6508 121.983 47.0954 122.086 47.5453 122.174C48.8089 122.42 50.1146 122.55 51.4505 122.55C54.0336 122.55 56.5037 122.066 58.7752 121.185C66.3177 118.258 71.6708 110.945 71.7007 102.379L71.7015 6.76465H89.1492ZM50.5877 58.1406L50.5872 52.7175C48.5742 52.4425 46.5447 52.3045 44.5127 52.3055C20.0612 52.305 0.240234 72.1155 0.240234 96.5515C0.240234 111.871 8.02969 125.373 19.8672 133.315C12.0597 125.338 7.25 114.421 7.25 102.378C7.25 78.2532 26.5682 58.6366 50.5877 58.1406Z" fill="#00F2EA" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M50.5877 77.1897C48.5997 76.5597 46.5267 76.2396 44.4417 76.2416C46.5847 76.2416 48.6502 76.5752 50.5877 77.1897ZM37.6547 77.4062C31.0581 79.7507 26.0372 85.4163 24.6051 92.3903C24.6042 92.3945 24.6034 92.3987 24.6026 92.4028C26.0314 85.4235 31.0543 79.7523 37.6547 77.4062ZM35.3315 114.564L35.3292 114.563C38.0105 118.08 41.8169 120.693 46.2121 121.866C41.818 120.693 38.0127 118.08 35.3315 114.564ZM104.439 29.3771C107.332 32.535 110.822 35.1377 114.732 37.006C110.864 35.1599 107.363 32.5773 104.439 29.3771Z" fill="black"
          />
          <path d="M122.16 58.3751V39.5322C119.772 39.0237 117.478 38.2613 115.308 37.2745C115.115 37.1868 114.923 37.0973 114.732 37.006C110.864 35.1599 107.363 32.5773 104.439 29.3771C96.5242 24.4411 90.8212 16.2971 89.1492 6.76465H71.7015V102.378C71.6711 110.944 66.3182 118.258 58.7752 121.185C56.5037 122.066 54.0336 122.55 51.4505 122.55C50.1146 122.55 48.8089 122.42 47.5453 122.174C47.0954 122.086 46.6508 121.983 46.2121 121.866C41.8169 120.693 38.0105 118.08 35.3292 114.563L35.3315 114.564C35.331 114.563 35.3305 114.563 35.33 114.562C31.2002 112.479 27.8817 109.024 25.9735 104.797C24.8284 102.26 24.1912 99.4456 24.1912 96.4817C24.1912 95.0845 24.3328 93.7203 24.6026 92.4028L24.6051 92.3903C26.0372 85.4163 31.0581 79.7507 37.6547 77.4062C38.5303 77.095 39.4338 76.8423 40.3602 76.6528C41.6786 76.3832 43.0436 76.2416 44.4417 76.2416C46.5267 76.2396 48.5997 76.5597 50.5877 77.1897V58.1406C26.5682 58.6366 7.25 78.2532 7.25 102.378C7.25 114.421 12.0597 125.338 19.8672 133.315C26.9107 138.043 35.3897 140.799 44.5122 140.799C68.9612 140.799 88.7822 120.99 88.7822 96.5517V47.7001C98.1917 54.4206 109.714 58.3751 122.16 58.3751Z" fill="white" />
        </svg>
      ),
    },
    {
      platform: 'instagram',
      displayIf: cmsInfo.instagramLink !== null,
      title: `Instagram | ${cmsInfo.instagramLink}`,
      buttonColor: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
      socialIcon: <RiInstagramLine className={socialIconColor} size="29" />,
      onClickAction: () => openInNewTab(cmsInfo.instagramLink)
    },
    {
      platform: 'linkedin',
      displayIf: cmsInfo.linkedInLink !== null,
      title: `LinkedIn | ${cmsInfo.linkedInLink}`,
      buttonColor: '#0966C2',
      socialIcon: <FaLinkedinIn className={socialIconColor} size={iconSize} />,
      onClickAction: () => openInNewTab(cmsInfo.linkedInLink),
    },
    {
      platform: 'facebook',
      displayIf: cmsInfo.facebookLink !== null,
      title: `Facebook | ${cmsInfo.facebookLink}`,
      buttonColor: 'linear-gradient(to top, #0062E0, #19AFFF)',
      socialIcon: <FaFacebookF className={socialIconColor} size={iconSize} />,
      onClickAction: () => openInNewTab(cmsInfo.facebookLink),
    },
    {
      platform: 'phone',
      displayIf: phoneNumber !== null,
      title: `${phoneNumber}`,
      buttonColor: '#444444',
      socialIcon: <FaPhoneAlt className={socialIconColor} size="21" />,
      onClickAction: () => callPhoneNumber(phoneNumber),
    },
    {
      platform: 'email',
      displayIf: emailAddress !== null,
      title: `${emailAddress}`,
      buttonColor: '#444444',
      socialIcon: <TbMailFilled className={socialIconColor} size={iconSize} />,
      onClickAction: () => sendEmail(emailAddress),
    },
    {
      platform: 'contactUs',
      displayIf: true,
      title: 'Contact Us',
      buttonColor: '#444444',
      socialIcon: <RiQuestionAnswerFill className={socialIconColor} size={iconSize} />,
      onClickAction: () => navigateToPage('/contact-sip-225'),
    },
    // {
    //   platform: 'memberPortal',
    //   displayIf: true,
    //   buttonText: 'Members',
    //   socialIcon: <MdSupervisedUserCircle className={socialIconColor} size="29" />,
    //   onClickAction: () => setIsMembersModalOpen(true),
    //   className: 'member-portal',
    // },
    {
      platform: 'mobileMenuToggle',
      displayIf: true,
      socialIcon: <FiMenu className="primary--clr" size="37" />,
      onClickAction: () => toggleDrawer(),
      className: 'mobile-menu-toggle',
    },
  ];

  const socialItemsHeaderNav = socialIconsRef.filter((item) => !['phone', 'email'].includes(item.platform));

  const socialItemsMobileNav = socialIconsRef.filter((item) => ['mobileMenuToggle'].includes(item.platform));

  const socialItemsDrawerNav = socialIconsRef.filter((item) => ['contactUs', 'googleMap', 'facebook', 'instagram', 'admin'].includes(item.platform));

  // const socialItemsFooter = socialIconsRef.filter((item) => ['googleMap', 'instagram', 'linkedin', 'facebook', 'contactUs', 'admin'].includes(item.platform));

  return (
    <>
      {/* MOBILE NAVIGATION */}
      <CustomBlock className={`mobile-navigation ${scroll ? 'mobile-navigation__on-scroll' : ''}`}>
        <CustomBlock className={`navigation-block ${backgroundColor}`}>
          {/* MOBILE NAVIGATION - LOGO BLOCK */}
          <CustomBlock className="logo-block">
            <Image
              source={logo}
              to="/"
            />
          </CustomBlock>

          {/* MOBILE NAVIGATION - ICONS */}
          <CustomBlock className="icons-block">
            {socialItemsMobileNav.map((item, index) => (
              <SocialIconButton
                key={index}
                displayIf={item.displayIf}
                buttonColor={item.buttonColor}
                buttonText={item.buttonText}
                socialIcon={item.socialIcon}
                onClickAction={item.onClickAction}
                className={`social-icon ${item.className || ''}`}
                title={item.title}
              />
            ))}
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* HEADER NAVIGATION */}
      <CustomBlock className={`header-navigation-container ${scroll ? 'header-navigation-container__on-scroll' : ''}`}>
        {/* HEADER NAVIGATION - PRIMARY */}
        <CustomBlock className={`header-navigation ${scroll ? 'header-navigation__on-scroll' : ''}`}>
          {
            hasSearchBlock
            && (
              <CustomBlock className={`features-block ${backgroundColor}`}>
                {/* LOGO */}
                <CustomBlock className="logo-block">
                  <Image
                    // source={scroll ? companyLogos.defaultLight : companyLogos.defaultDark}
                    source={logo}
                    to="/"
                  />
                </CustomBlock>

                {/* SEARCH */}
                {
                  <CustomBlock className="search-block">
                    <SelectBlock
                      isAsync
                      placeholder="Search keywords, descriptions and titles..."
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();

                          history.push('/deals', {
                            predefinedFilter: {
                              sortBy: '',
                              priceRange: '',
                              categories: null,
                              searchTerm: e.target.value,
                              productTypes: '',
                              featuredSections: null,
                              skip: 0
                            }
                          });

                          e.target.blur();
                        }
                      }}
                      autoBlur
                      backgroundColor="white--sbg"
                      value={[]}
                      defaultOptions={[]}
                      // loadOptions={loadSuggestions}
                      noOptionsMessage={(e) => {
                        return (e.inputValue !== '') ? 'No Results Available' : 'Start typing and press enter to search';
                      }}
                      formatOptionLabel={(option) => (
                        <CustomBlock className="content-container--actions flex-start pl-0 mt-0 mb-10">
                          <CustomBlock className="smaller-image-container">
                            <Image
                              source={`${process.env.REACT_APP_API_URL}Attachments/${option.filePath}`}
                            />
                          </CustomBlock>
                          <p className="ml-15 fw-400">{option.label}</p>
                        </CustomBlock>
                      )}
                      onChange={(e) => {
                        history.push(`/deal/details/${e.value}`);
                      }}
                    />
                    {
                      hasChipFilter
                      && (
                        <CustomBlock className="chip-filter-block">
                          <CustomBlock className="chips-container">
                            {
                              popularSelections.map((item) => (
                                <Chip
                                  hasBorder
                                  label={item.name}
                                  onClick={() => {

                                  }}
                                />
                              ))
                            }
                          </CustomBlock>
                        </CustomBlock>
                      )
                    }
                  </CustomBlock>
                }

                {/* ICONS BLOCK */}
                <CustomBlock className="icons-block">
                  {
                    primarySocialMediaLink !== null
                    && (
                      <button
                        onClick={() => openInNewTab(primarySocialMediaLink)}
                        className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                      >
                        <FiFacebook className={socialIconColor} size={iconSize} />
                      </button>
                    )
                  }

                  {
                    phoneNumber !== null
                    && (
                      <button
                        onClick={() => openInNewTab(secondarySocialMediaLink)}
                        className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                      >
                        <RiInstagramLine className={socialIconColor} size={iconSize} />
                      </button>
                    )
                  }

                  {
                    emailAddress !== null
                    && (
                      <button
                        onClick={() => {
                          sendEmail(emailAddress);
                        }}
                        className={`social-icon ${socialIconBackgroundColor} ${iconAppearance ? iconAppearanceOptions[iconAppearance] : 'square'}`}
                      >
                        <MdMailOutline className={socialIconColor} size={iconSize} />
                      </button>
                    )
                  }

                  <Button
                    text={auth.authToken ? 'Dashboard' : 'Login'}
                    className="white--clr primary-lighter--sbg"
                    onClick={() => {
                      openInNewTab(`${auth.authToken ? '/cms/management/pages' : '/login'}`);
                    }}
                  />
                </CustomBlock>
              </CustomBlock>
            )
          }

          {
            !hasSearchBlock
            && (
              <CustomBlock className="logo-block">
                <Image
                  source={logo}
                  to="/"
                />
              </CustomBlock>
            )
          }

          {/* MENU ITEMS */}
          <CustomBlock className={`menu-items-block ${hasSearchBlock ? menuItemsBlockBackgroundColor : ''} ${scroll ? 'menu-items-block__on-scroll' : ''}`}>

            <ul className={`menu-items ${!hasSearchBlock && 'has-width'}`}>
              <li>
                <Button
                  text="Home"
                  to="/home"
                  className={`menu-item hover-animation--underline ${menuItemColor}`}
                />
              </li>
              {
                menuItems.map((menuItem) => (
                  menuItem.assignedMenuItems.length > 0
                    ? (
                      <li className="has-submenu">
                        <Button
                          text={menuItem.name}
                          className={`menu-item hover-animation--underline ${menuItemColor}`}
                          onClick={() => {
                            handleMenuItemClick(menuItem);
                          }}
                        />
                        <CustomBlock className={`submenu-block default ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                          <ul className="submenu-items-block default">
                            {
                              menuItem.assignedMenuItems.map((submenuItem) => (
                                <li key={submenuItem.id}>
                                  <Button
                                    text={submenuItem.name}
                                    onClick={() => {
                                      handleMenuItemClick(submenuItem);
                                    }}
                                    className={`menu-item ${submenuItemColor || menuItemColor}`}
                                  />
                                </li>
                              ))
                            }
                          </ul>
                        </CustomBlock>
                      </li>
                    ) : (

                      <li>
                        <Button
                          text={menuItem.name}
                          onClick={() => {
                            handleMenuItemClick(menuItem);
                          }}
                          className={`menu-item hover-animation--underline ${menuItemColor}`}
                        />
                      </li>
                    )
                ))
              }
            </ul>

          </CustomBlock>

          {/* HEADER NAVIGATION - SECONDARY */}
          <CustomBlock className="header-navigation-secondary">
            <CustomBlock className="icons-block">
              {socialItemsHeaderNav.map((item, index) => (
                <SocialIconButton
                  key={index}
                  displayIf={item.displayIf}
                  buttonColor={item.buttonColor}
                  buttonText={item.buttonText}
                  socialIcon={item.socialIcon}
                  onClickAction={item.onClickAction}
                  className={`social-icon ${item.className || ''}`}
                  title={item.title}
                />
              ))}
            </CustomBlock>
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>

      {/* DRAWER NAVIGATION */}
      <DrawerNavigation
        hasOverlay
        isOpen={isDrawerOpen}
        willCloseOnMaskPress
        onClick={() => {
          toggleDrawer();
        }}
        className="drawer-navigation"
        headerItems={(
          <CustomBlock className="logo-block">
            <Image
              // source={`${process.env.REACT_APP_API_URL}Attachments/${logo}`}
              source={logo}
              to="/"
              containerClassName="mobile-drawer-logo-container"
            />
          </CustomBlock>
        )}
        footerItems={(
          <CustomBlock className="drawer-navigation__social-block">
            {socialItemsDrawerNav.map((item, index) => (
              <SocialIconButton
                key={index}
                displayIf={item.displayIf}
                buttonColor={item.buttonColor}
                buttonText={item.buttonText}
                socialIcon={item.socialIcon}
                onClickAction={item.onClickAction}
                className={`social-icon ${item.className || ''}`}
                title={item.title}
              />
            ))}
          </CustomBlock>
        )}
      >
        {/* DRAWER NAVIGATION - NAVIGATION BLOCK */}
        <ul className="drawer-menu-items">
          <li>
            <Button
              text="Home"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/');
              }}
            />
          </li>
          {
            menuItems.map((menuItem) => (
              menuItem.assignedMenuItems.length > 0
                ? (
                  <li className="has-submenu">
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      iconRight={<TiArrowSortedDown size={25} className="primary-lightest--clr ml-15" />}
                      onClick={() => {
                        toggleMobileSubmenu(menuItem.id);
                      }}
                    />
                    <CustomBlock className={`submenu-block default ${expandedMenuId === menuItem.id ? 'visible' : 'hidden'} ${submenuBlockBackgroundColor} ${hasRoundCorners ? 'round-corners' : ''}`}>
                      <ul className="submenu-items-block default">
                        {
                          menuItem.assignedMenuItems.map((submenuItem) => (
                            <li key={submenuItem.id}>
                              <Button
                                text={submenuItem.name}
                                className={`menu-item ${submenuItemColor || menuItemColor}`}
                                onClick={() => {
                                  handleMenuItemClick(submenuItem);
                                }}
                              />
                            </li>
                          ))
                        }
                      </ul>
                    </CustomBlock>
                  </li>
                ) : (

                  <li>
                    <Button
                      text={menuItem.name}
                      className={`menu-item hover-animation--underline ${mobileMenuItemColor}`}
                      onClick={() => {
                        handleMenuItemClick(menuItem);
                      }}
                    />
                  </li>
                )
            ))
          }
          <li>
            <Button
              text="Admin Login"
              className={`menu-item menu-item hover-animation--underline ${mobileMenuItemColor}`}
              onClick={() => {
                navigateToPage('/login');
              }}
            />
          </li>
        </ul>
      </DrawerNavigation>

      {/* MEMBERS MODAL BLOCK */}
      {/* <Modal
        hasCloseAction
        isVisible={isMembersModalOpen}
        size="md"
        // contentHeader="Member Resources"
        contentHeader={<p><span className="custom-header--primary-light">Member</span> Resources</p>}
        primaryModalActionText=""
        primaryModalActionOnClick=""
        primaryModalActionColor=""
        secondaryModalActionText=""
        secondaryModalActionOnClick=""
        secondaryModalActionColor=""
        onHide={() => {
          setIsMembersModalOpen(false);
        }}
      >
        <Section hasNoContainer className="members-modal">
          <CustomBlock className="members-modal__listing-container">
            <Button
              hasRoundCorners
              type="button"
              onClick=""
              // className="primary--sbg"
              size="sm"
              text="Login"
            />
            <Button
              hasRoundCorners
              type="button"
              onClick={() => {
                navigateToPage('/membership-benefits');
                setIsMembersModalOpen(false);
              }}
              // className="primary--sbg"
              size="sm"
              text="View Benefits"
            />
            <Button
              hasRoundCorners
              type="button"
              onClick=""
              // className="primary--sbg"
              size="sm"
              text="Membership Levels"
            />
            <Button
              hasRoundCorners
              type="button"
              onClick=""
              // className="primary--sbg"
              size="sm"
              text="Member Directory"
            />
            <Button
              hasRoundCorners
              type="button"
              onClick=""
              // className="primary--sbg"
              size="sm"
              text="Member2Member Deals"
            />
          </CustomBlock>
        </Section>
      </Modal> */}
    </>
  );
};

HeaderNavigation.propTypes = {
  // MAIN PROPS
  // layout: PropTypes.string.isRequired,
  logo: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  menuItemsBlockBackgroundColor: PropTypes.string,
  fixedNavigationBarBackgroundColor: PropTypes.string.isRequired,
  // to: PropTypes.string,

  // NAVIGATION PROPS
  menuItemColor: PropTypes.string,
  menuToggleIconColor: PropTypes.string,
  menuToggleIconSize: PropTypes.number,
  mobileMenuItemColor: PropTypes.string,

  // SUB MENU PROPS
  submenuBlockBackgroundColor: PropTypes.string,
  submenuItemColor: PropTypes.string,

  // SOCIAL MEDIA PROPS
  iconSize: PropTypes.number,
  iconAppearance: PropTypes.string.isRequired,
  socialIconBackgroundColor: PropTypes.string,
  socialIconColor: PropTypes.string,
  primarySocialMediaLink: PropTypes.string,
  secondarySocialMediaLink: PropTypes.string,

  // PRIMARY ICON PROPS
  // primaryActionIcon: PropTypes.any,
  // primaryActionIconText: PropTypes.string,

  // SECONDARY ICON PROPS
  // secondaryActionIcon: PropTypes.any,
  // secondaryActionIconText: PropTypes.string,

  // FUNCTION PROPS
  //   // primaryActionOnClick: PropTypes.func,
  // secondaryActionOnClick: PropTypes.func,

  // BOOLEANS PROPS
  hasSearchBlock: PropTypes.bool,

  // menuItems: PropTypes.array,
  hasChipFilter: PropTypes.bool,
  // hasFeaturedItems: PropTypes.bool,
  hasRoundCorners: PropTypes.bool,
};

HeaderNavigation.defaultProps = {
  // MAIN PROPS
  backgroundColor: 'white--sbg',
  menuItemsBlockBackgroundColor: null,
  // to: '',

  // NAVIGATION PROPS
  menuItemColor: 'primary--clr',
  menuToggleIconColor: 'primary--clr',
  menuToggleIconSize: 35,
  mobileMenuItemColor: 'dark--clr',

  // SUB MENU PROPS
  submenuBlockBackgroundColor: 'white--sbg',
  submenuItemColor: 'primary--clr',

  // SOCIAL MEDIA PROPS
  iconSize: 23,
  socialIconColor: 'white--clr',
  socialIconBackgroundColor: 'primary-lighter--sbg',
  primarySocialMediaLink: '',
  secondarySocialMediaLink: '',

  // // BOOLEANS PROPS
  hasSearchBlock: false,
  hasChipFilter: false,
  // hasFeaturedItems: false,
  hasRoundCorners: false,
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, null)(HeaderNavigation);