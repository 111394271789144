/* eslint-disable react/no-unknown-property */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Fade } from 'react-awesome-reveal';
// COMPONENTS
import Slider from 'react-slick';
// CUSTOM COMPONENTS
import CustomBlock from './CustomBlock';
import Image from './Image';
import Overlay from './Overlay';
// ASSETS
import LogoAlt1 from '../assets/img/logo-alt-1.svg';
import LogoAlt2 from '../assets/img/logo-alt-2.svg';
import BannerImage from '../assets/img/banner-image_default.png';
import IllustrationSquare from '../assets/img/illustration-square.svg';
// HELPERS
import getSettingByKey from '../services/management/settingService';
import constants from '../constants/constants';

// const quickActions = [
//   {
//     text: 'About Us',
//     to: '/about-livingston-parish-drainage-district-2',
//     className: 'light--clr fw-600 hover-2',
//     isTextUppercase: true,
//     type: 'button',
//   },
//   // {
//   //   text: 'Latest Alerts',
//   //   to: '/latest-alerts',
//   //   className: 'light--clr fw-600 hover-2',
//   //   isTextUppercase: true,
//   //   type: 'button',
//   // },
//   {
//     text: 'Report An Issue',
//     to: '/contact-livingston-parish-drainage-district-2',
//     className: 'light--clr fw-600 hover-1',
//     isTextUppercase: true,
//     type: 'button',
//   },
// ];

const CustomDescription = () => (
  <CustomBlock className="custom-description">
    <CustomBlock>
      <img
        src={IllustrationSquare}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">Foundations for Lifelong Learning</p>
    </CustomBlock>
    <CustomBlock>
      <img
        src={IllustrationSquare}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">Personalized Play and Therapy</p>
    </CustomBlock>
    <CustomBlock>
      <img
        src={IllustrationSquare}
        alt="llustration"
      />
      <p className="dark-grey--clr custom-banner-description">A Community Celebrating Every Child</p>
    </CustomBlock>
  </CustomBlock>
);

const RotatingImageSlider = (props) => {
  const { length } = props;
  const rotatingImages = Array.from({ length }, (_, i) => `${constants.API_URL}Attachments/RotatingImages/image-${i + 1}.png`);

  const customPagingElement = () => (
    <a href="#!">
      <svg width="100%" height="100%" fill="current-color" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.73162 -0.00195312L0.470703 8.31551L8.73162 16.633L16.9925 8.31537L8.73162 -0.00195312Z" fill="current-color" />
      </svg>
    </a>
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: customPagingElement,
    dotsClass: 'slick-dots custom-dots',
    fade: true,
    // adaptiveHeight: true,
  };

  return (
    <Slider
      {...sliderSettings}
      className="custom-banner__slider"
    >
      {rotatingImages.map((imageUrl, index) => (
        <img
          key={imageUrl}
          src={imageUrl}
          alt={`Slide ${index}`}
          className="slick-image"
        />
      ))}
    </Slider>
  );
};

const CustomBannerInsert = (props) => {
  const { history, offsetValue } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [rotatingImagesLength, setRotatingImagesLength] = useState('');

  useEffect(() => {
    // FETCH ARRAY LENGTH - FROM [Sip225].[dbo].[Settings]
    if (!rotatingImagesLength) {
      const fetchData = async () => {
        setIsLoading(true);
        try {
          const res = await getSettingByKey('RotatingImagesLength');
          setRotatingImagesLength(res);
        } catch (ex) {
          // showAlert({ text: ex.message, state: 'error' });
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, []);

  return (
    <>
      {isLoading && <Overlay />}
      <CustomBlock
        className="custom-banner"
        style={{
          backgroundImage: `url(${BannerImage})`,
          backgroundPositionY: `${offsetValue * 0.25}px`,
          backgroundPositionX: '5%',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <CustomBlock className="custom-banner__primary">
          {/* LOGO */}
          <Image
            source={LogoAlt1}
            alt="Company Logo"
            containerClassName="image-container"
          />

          {/* DIVIDER */}
          <CustomBlock>
            <Fade direction="left">
              <svg width="251" height="18" viewBox="0 0 251 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M111.201 10.0927H0.128906V8.3176H111.201V10.0927Z" fill="#3f4254" />
                <path d="M125.129 0.887695L116.868 9.20515L125.129 17.5226L133.39 9.20502L125.129 0.887695Z" fill="#3f4254" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M250.129 10.0927H139.057V8.3176H250.129V10.0927Z" fill="#3f4254" />
              </svg>
            </Fade>
          </CustomBlock>

          {/* QUOTE */}
          <Fade direction="right">
            <CustomBlock className="description-container">
              <p className="dark-grey--clr size-xl fw-700">
                &quot;A Sip Above the Rest&quot;
              </p>
            </CustomBlock>
          </Fade>

          <CustomBlock className="mt-20">
            <Fade delay={300} duration={1500}>
              <CustomBlock className="button-style-1">
                <button className="learn-more" onClick={() => history.push('/about-sip-225')}>
                  <span className="circle" aria-hidden="true">
                    <span className="icon arrow" />
                  </span>
                  <span className="button-text">Learn More</span>
                </button>
              </CustomBlock>
            </Fade>
          </CustomBlock>
        </CustomBlock>

        <CustomBlock className="custom-banner__secondary">
          <Image
            source={LogoAlt2}
            alt="Company Logo"
            containerClassName="image-container"
          />
          <RotatingImageSlider
            length={rotatingImagesLength}
          />
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

export default CustomBannerInsert;