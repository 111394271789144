/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-max-props-per-line */
/* eslint-disable no-use-before-define */
// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Slider from 'react-slick';
import { Fade } from 'react-awesome-reveal';
// ICONS
import {
  MdEmail,
  MdLocalPhone,
  MdLocationPin,
} from 'react-icons/md';
// CUSTOM COMPONENTS
import CustomBlock from '../../../components/CustomBlock';
import Banner from '../../../components/Banner';
import Overlay from '../../../components/Overlay';
import ContactForm from '../../../components/ContactForm';
import Image from '../../../components/Image';
// import ContentSection from '../../../components/ContentSection';
// ASSETS
import BannerImage from '../../../assets/img/banner-image_default.png';
import Logo from '../../../assets/img/logo.svg';
// import IntroSectionImage from '../../../assets/img/content-image_contact-page-intro.png';
// SERVICES AND HELPERS
import * as auth from '../../../redux/authRedux';
import * as alertToast from '../../../redux/alertToastRedux';
import * as emailService from '../../../services/management/emailService';
import getSettingByKey from '../../../services/management/settingService';
import constants from '../../../constants/constants';

const RotatingImageSlider = (props) => {
  const { containerClassName } = props;
  const rotatingImages = Array.from({ length: 5 }, (_, i) => `${constants.API_URL}Attachments/RotatingImages/image-${i + 1}.png`);

  const customPagingElement = () => (
    <a href="#!">
      <svg width="100%" height="100%" fill="current-color" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.73162 -0.00195312L0.470703 8.31551L8.73162 16.633L16.9925 8.31537L8.73162 -0.00195312Z" fill="current-color" />
      </svg>
    </a>
  );

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    customPaging: customPagingElement,
    dotsClass: 'slick-dots custom-dots',
    fade: true,
  };

  return (
    <Slider
      {...settings}
      className={`${containerClassName}`}
    >
      {rotatingImages.map((imageUrl, index) => (
        <img
          src={imageUrl}
          alt={`Slide ${index}`}
          className="slick-image"
        />
      ))}
    </Slider>
  );
};

const ContactPage = (props) => {
  const { showAlert, history, cmsInfo, companyLogo } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [googleMapURL, setGoogleMapURL] = useState('');
  const [rotatingImagesLength, setRotatingImagesLength] = useState('');

  const formConfig = [
    { name: 'firstName', label: 'First Name', required: true, placeholder: 'John', max: 100 },
    { name: 'lastName', label: 'Last Name', required: true, placeholder: 'Smith', max: 100 },
    { name: 'email', label: 'Email Address', required: true, placeholder: 'johnsmith@example.com', max: 100, email: true },
    { name: 'phoneNumber', label: 'Phone Number', placeholder: '(555) 555-5555', max: 50 },
    { name: 'message', label: 'Message', required: true, placeholder: 'Provide your message here...', textarea: true, cols: 12 },
  ];

  useEffect(() => {
    if (!googleMapURL || !rotatingImagesLength) {
      fetchData();
    }
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const googleMapURLRes = await getSettingByKey('GoogleMapURL');
      setGoogleMapURL(googleMapURLRes);

      const rotatingImagesLengthRes = await getSettingByKey('RotatingImagesLength');
      setRotatingImagesLength(rotatingImagesLengthRes);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const submitForm = async (values) => {
    setIsLoading(true);
    await emailService.sendEmail(values).then(() => {
      showAlert({ text: 'Success! Your message has been received.', state: 'success' });
      history.push('/contact');
    }).catch((ex) => {
      showAlert({ text: ex.message, state: 'error' });
    }).finally(() => {
      setIsLoading(false);
    });
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <Banner
        isDynamicBanner
        isTitleUppercase
        title="Contact"
        imageSource={BannerImage}
      />

      <CustomBlock className="main-content">
        {/* COMPANY LOGO SECTION */}
        <CustomBlock className="content-section logo-section pb-20">
          <Image
            containerClassName="logo-container"
            className="logo"
            source={Logo}
          />
          <RotatingImageSlider
            containerClassName="image-container"
          />
        </CustomBlock>

        <svg
          width="100%"
          height="600"
          // viewBox="0 0 1901 602"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          className="background-decoration--dark-light-waves"
          style={{ transform: 'scaleX(1)', bottom: '20%', position: 'absolute', zIndex: '1', maxWidth: '2000px' }}
        >
          <path d="M0 73.5365V397.818C107.347 321.272 469.441 120.081 987.671 199.212C1483.16 274.87 1645.55 264.416 1900 205.456V172.838C1530.73 216.282 1327.47 180.596 922.504 73.5365C582.707 -25.7691 342.211 -22.6638 0 73.5365Z" fill="url(#paint0_linear_64_3286)" />
          <path d="M0 397.818V601.783C116.656 476.385 478.462 214.324 987.671 251.966C1489.77 289.082 1583.44 303.763 1900 221.134V205.456C1645.55 264.416 1483.16 274.87 987.671 199.212C469.441 120.081 107.347 321.272 0 397.818Z" fill="url(#paint1_linear_64_3286)" fillOpacity="0.9" />
          <defs>
            <linearGradient id="paint0_linear_64_3286" x1="0.0959514" y1="0.219064" x2="1899.99" y2="0.219064" gradientUnits="userSpaceOnUse">
              <stop stopColor="#765A2B" />
              <stop offset="0.206149" stopColor="#A78038" />
              <stop offset="0.78" stopColor="#F3E589" />
            </linearGradient>
            <linearGradient id="paint1_linear_64_3286" x1="0.0959514" y1="181.101" x2="1899.99" y2="181.101" gradientUnits="userSpaceOnUse">
              <stop stopColor="#765A2B" />
              <stop offset="0.62" stopColor="#F3ECC2" />
              <stop offset="1" stopColor="#F3E589" />
            </linearGradient>
          </defs>
        </svg>

        <CustomBlock className="content-section flex-container" style={{ zIndex: '4' }}>
          {/* COMPANY DETAILS SECTION */}
          <CustomBlock className="details-section">
            <Fade direction="up">
              <h2 className="size-lg animated-text-gradient--primary fw-700 mb-10">Contact</h2>
            </Fade>
            <CustomBlock className="contact">
              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdEmail size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">{cmsInfo.emailAddress}</p>
              </CustomBlock>

              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdLocalPhone size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">{cmsInfo.phoneNumber}</p>
              </CustomBlock>
            </CustomBlock>

            <CustomBlock className="location mt-30">
              <Fade direction="up" delay={300}>
                <h2 className="size-lg animated-text-gradient--primary fw-700 mb-10">Location</h2>
              </Fade>
              <CustomBlock className="contact__row">
                <CustomBlock className="contact__row__icon">
                  <MdLocationPin size="18" className="light--clr" />
                </CustomBlock>
                <p className="size-md mid-grey--clr">
                  {cmsInfo.streetAddress1}, {cmsInfo.streetAddress2}<br />
                  {cmsInfo.city}, {cmsInfo.state} {cmsInfo.zipCode}
                </p>
              </CustomBlock>
            </CustomBlock>

            {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours || cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
              <CustomBlock className="hours mt-30">
                <Fade direction="up" delay={600}>
                  <h2 className="size-lg animated-text-gradient--primary fw-700 mb-10">Hours of Operation</h2>
                </Fade>
                <p className="size-md mid-grey--clr">
                  {(cmsInfo.primaryWeekDayHours || cmsInfo.secondaryWeekDayHours) && (
                    <CustomBlock className="">
                      {cmsInfo.primaryWeekDayHours && `${cmsInfo.primaryWeekDayHours}`}
                      <br />
                      {cmsInfo.secondaryWeekDayHours && `${cmsInfo.secondaryWeekDayHours}`}
                    </CustomBlock>
                  )}

                </p>
                {(cmsInfo.primaryWeekEndHours || cmsInfo.secondaryWeekEndHours) && (
                  <CustomBlock>
                    {cmsInfo.primaryWeekEndHours && (
                      <p>{cmsInfo.primaryWeekEndHours}</p>
                    )}
                    {cmsInfo.secondaryWeekEndHours && (
                      <p>{cmsInfo.secondaryWeekEndHours}</p>
                    )}
                  </CustomBlock>
                )}
              </CustomBlock>
            )}
          </CustomBlock>

          {/* GOOGLE MAPS SECTION */}
          {googleMapURL.length > 0 && (
            <CustomBlock className="google-map-section">
              <iframe
                title="Company Google Map"
                frameBorder="0"
                style={{ border: '0 !important' }}
                src={googleMapURL}
                allowFullScreen
              />
            </CustomBlock>
          )}
        </CustomBlock>

        {/* FORM */}
        <CustomBlock className="content-section" style={{ position: 'relative', zIndex: '2' }}>
          <CustomBlock className="content-container--card-style--with-shadow">
            {/* FORM HEADER */}
            <CustomBlock className="form-header">
              <Fade direction="right" cascade className="form-header">
                <p className="size-xl fw-600 animated-text-gradient--primary">Questions?</p>
              </Fade>

              <Fade direction="left" cascade className="form-header">
                <p className="size-xl fw-700 animated-text-gradient--primary">We Have Answers</p>
              </Fade>

              <Fade delay={500} duration={2000}>
                <CustomBlock className="mid-grey--clr mt-20 mb-20">
                  <svg width="251" height="18" viewBox="0 0 251 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M111.201 10.0927H0.128906V8.3176H111.201V10.0927Z" fill="currentColor" />
                    <path d="M125.129 0.887695L116.868 9.20515L125.129 17.5226L133.39 9.20502L125.129 0.887695Z" fill="currentColor" />
                    <path fillRule="evenodd" clipRule="evenodd" d="M250.129 10.0927H139.057V8.3176H250.129V10.0927Z" fill="currentColor" />
                  </svg>
                </CustomBlock>
              </Fade>
            </CustomBlock>

            {/* FORM */}
            <ContactForm
              formConfig={formConfig}
              onSubmit={(values) => {
                submitForm(values);
              }}
              title=""
            />
          </CustomBlock>
        </CustomBlock>
      </CustomBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(mapStateFromProps, {
  ...auth.actions,
  ...alertToast.actions
})(ContactPage);